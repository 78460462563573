<template>
    <div>
        <h2><router-link :to="{ name: 'tag' }">จัดการแท็ก</router-link> > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}แท็ก</h2>
        <v-card class="mt-4">
          <v-card-title>ประเภทช่องทาง</v-card-title>

          <v-card-text>
            <v-form v-model="isFormValid" @submit.prevent="submitForm">              
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อแท็ก *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                    :disabled="$store.getters['app/GET_APP_MODE'] == 'read'"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>            
          </v-card-text>          

          <v-card-actions>              
            <v-btn
              color="primary"               
              large          
              :disabled="!isFormValid"
              :loading="sendingData"
              @click="submitForm"
              v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
            >
              {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
            </v-btn>

            <v-btn
              color="secondary"               
              outlined                 
              large
              :disabled="sendingData"
              @click="$router.go(-1)"
            >
              กลับ
            </v-btn>                
          </v-card-actions>
        </v-card>
    </div>
</template>
<script>
import { maxLengthValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPost, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      formData: {
        name: null,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  watch: {
    'formData.parent': function (value) {
      if (!value) return (this.formData.parent_id = null)

      this.formData.parent_id = value.id
    },
  },
  async created() {
    const tag_id = this.$route.params.tag_id

    if (tag_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Product'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    this.$store.commit('app/SET_GLOBAL_LOADING', true)
    try {
      const { name, parent } = await asyncGet('/product/tag/' + tag_id)

      this.formData = {
        name: name,
        parent: parent,
        parent_id: !!parent ? parent.id : null,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      if (!this.isFormValid) return

      this.sendingData = true
      try {
        const tag_id = this.$route.params.tag_id
        if (tag_id == 'create') await asyncPost('/product/tag', this.formData)
        else {
          await asyncUpdate('/product/tag/' + tag_id, this.formData)
        }

        this.$router.push({ name: 'tag' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', this.$errorParser(error))
      }

      this.sendingData = false
    },
  },
  components: {},
}
</script>